import {javascript} from "@api/index";
import {useHydration} from "@stores/utils";
import {defineStore} from "pinia";
import {computed, ref} from "vue";

export type ParticipantStoreType = ReturnType<typeof useParticipantStore>
export const allParticipantStores = ref<Array<ParticipantStoreType>>([])

export const createParticipantStore = (uniqueId: string | number) => {
  const participantStore = defineStore('participant-store-' + String(uniqueId), () => {
    const hydration = useHydration<Backend.Models.Participant>(javascript.loggedParticipant)
    const hydrateById = (documentId: number, fields: Array<keyof Backend.Models.Participant> = []) => { return hydration.hydrate({ document_id: documentId }, fields) }
    allParticipantStores.value.push(participantStore as unknown as ParticipantStoreType)
    const edit = hydration.hydratedComputed('edit')
    const party = hydration.hydratedComputed('party_type')
    const displayName = hydration.hydratedComputed('display_name')
    const name = hydration.hydratedComputed('name')

    return {
      ...hydration,
      hydrateById,
      id: hydration.hydratedComputed('id'),
      userId: hydration.hydratedComputed('user_id'),
      party,
      isController: hydration.hydratedComputed('approve', approve => approve === 'All'),
      canEdit: hydration.hydratedComputed('edit', edit => edit !== 'No'),
      canSign: hydration.hydratedComputed('sign', sign => sign !== 'No'),
      edit,
      rights: computed(() => {
        switch (edit.value) {
          case 'All':
            return 'edit' as const
          case 'Comments':
            return 'comment' as const
          case 'Data':
            return 'questionnaire' as const
          case 'No':
            return 'view-only' as const
        }
      }),
      displayName: computed(() => {
        try {
          if(displayName.value) return displayName.value;
          if(name.value) return name.value;
        } catch(_: unknown) {}
        return "";
      }),
      name,

    }
  })
  return participantStore
}

export const useParticipantStore = createParticipantStore('current')