import { javascript } from '@api/index'
import { defineStore, getActivePinia } from 'pinia'
import { createParticipantStore } from "@stores/generic/participant.store";
import { useHydration } from "@stores/utils";
import { computed, ref } from "vue";

export type DocumentStoreType = ReturnType<typeof useDocumentStore>
export const allDocumentStores = ref<Array<DocumentStoreType>>([])

type DocumentType = Backend.Models.Document & {
  participants: Backend.Models.Participant[],
  removed_participants: Backend.Models.Participant[],
  parties: string[],

  docx_settings: {formats: Backend.Models.TemplateVersion.StyleFormat},
  comments: ReadonlyArray<Backend.Models.Comment>
}

export const createDocumentStore = (uniqueId: string | number) => {
  const documentStore = defineStore('document-store-' + String(uniqueId), () => {
    const hydration = useHydration<DocumentType>(javascript.document)
    const hydrateById = (id: number, fields: ReadonlyArray<keyof DocumentType> = []) => { return hydration.hydrate({  id }, fields) }
    allDocumentStores.value.push(documentStore as unknown as DocumentStoreType)

    const styles = hydration.hydratedComputed('docx_settings', settings => settings)

    return {
      ...hydration,
      hydrateById,
      id: hydration.hydratedComputed('id'),
      isRecipientReview: hydration.hydratedComputed('recipient_review'),
      parties: hydration.hydratedComputed('parties'),
      participants: hydration.hydratedComputed('participants', (participants) => participants.map(participant => {
        const participantStore = createParticipantStore(participant.id)(getActivePinia())
        participantStore.hydratedData = participant
        participantStore.hydrated = true;
        return participantStore
      })),
      removedParticipants: hydration.hydratedComputed('removed_participants'),
      styles,
      defaultStyle: computed(() => {
        const entries = Object.entries(styles.value.formats)
        const result = entries.find(([key, value]) => value['default'] === true) ?? entries.find(([key]) => key === 'Normal');
        return {
          key: result?.[0] ?? '',
          ...(result?.[1] ?? {}),
        } as const
      }),
    }
  })
  return documentStore
}

export const useDocumentStore = createDocumentStore('current')

export const getCurrentDocumentId = (): number => {
  // Check if documentStore is hydrated
  const documentStore = useDocumentStore(getActivePinia())
  if (
    documentStore.hydrated &&
    documentStore.hydratedData &&
    documentStore.hydratedData.id != null
  )
    return documentStore.hydratedData.id

  // Otherwise fetch from hidden field
  const documentId = document.getElementById('document-id')
  if (documentId == null) throw new Error('#document-id not found')
  if (!documentId.hasAttribute('value'))
    throw new Error('Value attribute not found on #document-id')
  return +documentId.getAttribute('value')!
}
